// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

const hamburger = document.querySelector('.hamburger');
var OffcanvasMenu = document.getElementById('offcanvasMenu');
const hamburgerInner = document.getElementById('hamburgerInner');
var _y = window.pageYOffset;

hamburger.addEventListener('click', function(){ 
    hamburger.classList.add('is-active');
});
OffcanvasMenu.addEventListener('shown.bs.offcanvas', function () {
    hamburger.classList.add('is-active');
});
OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
    hamburger.classList.remove('is-active');
});

window.addEventListener('scroll', function() {
    _y = window.pageYOffset;
    if(_y > 100) { // down
        hamburgerInner.classList.remove('light');
        hamburgerInner.classList.add('dark');
    } else {
        hamburgerInner.classList.remove('dark');
        hamburgerInner.classList.add('light');
    }

}, {passive: true});    

/*
offcanvasClose.forEach(function(e){
    console.log(e);
});
*/
/*
navMain.addEventListener('show.bs.collapse', function () {
    navBar.classList.remove('bg-transparent');
    navBar.classList.remove('navbar-dark');
    navBar.classList.add('bg-light');
    navBar.classList.add('navbar-light');
    hamburgerInner.classList.remove('light');
    hamburgerInner.classList.add('dark');
});
navMain.addEventListener('hide.bs.collapse', function () {
    if(_y <= 100){
        navBar.classList.remove('bg-light');
        navBar.classList.remove('navbar-light');
        navBar.classList.add('bg-transparent');
        navBar.classList.add('navbar-dark');
        hamburgerInner.classList.remove('dark');
        hamburgerInner.classList.add('light');    
    }
});
*/
